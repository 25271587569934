body {
  min-width: 300px;
  margin: 0;
  overflow-x: hidden;
  font-family: Arial, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.route-transition-enter {
  opacity: 0;
}

.route-transition-enter-active {
  opacity: 1;
  transition: all 200ms;
}

.route-transition-exit {
  opacity: 1;
}

.route-transition-exit-active {
  opacity: 0;
  transition: all 200ms;
}

.react-tooltip-custom {
  max-width: 400px;
  opacity: 1 !important;
  font-size: 14px !important;
}
